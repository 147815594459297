<template>
  <div class="home">
    <div class="home_header">
        <van-search @focus="goshous" v-model="params.keyword" placeholder="请输入搜索关键词" />
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in banners" :key="index">
                <img width="100%" :src="item.coverUrl" alt="" srcset="">
            </van-swipe-item>
        </van-swipe>
        <div class="tab_card">
            <div class="tab_nav" v-for="(item, index) in tabList" :key="index" @click="goshoppings(item.id)">
                <div class="tab_img">
                    <img :src="item.icon" alt="">
                </div>
                <div class="tab_text">{{item.name}}</div>
            </div>
        </div>
    </div>
    <!-- 商品 瀑布流 -->
    <div class="lazy_box">
        <div class="item" @click="goGoodDetails(item.id)" v-for="(item, index) in goodsList" :key="index">
            <img  :src="item.topPic" alt="" />
            <div class="intro">{{item.name}}</div>
            <div class="jiamu">
                <div class="price">￥{{item.price}}</div>
                <div class="stock">已售{{item.sales}}件</div>
            </div>
        </div>
    </div>
    <!-- 加载更多 -->
    <loadmore v-if="loading && isMore"/>
    <van-divider v-if="!isMore">到底啦~</van-divider>
    <bottomList></bottomList>
    <div class="menu">
      <menuBottom></menuBottom>
    </div>
  </div>
</template>

<script>
import loadmore from "@/views/components/business/loadmore";
import menuBottom from '@/views/components/business/menuBottom'
import { mallIndex, getmallList, searchGood } from '@/api/mallPage'
export default {
  name: "homeindex",
  components: {
    loadmore,
    menuBottom
  },
  data() {
    return {
        params: {
            keyword: '',
            cid: '',
            page: '',
            rows: ''
        },
        pager: {
            page: 1,
            rows: 20
        },
        banners: [], // 轮播图
        goodsList: [],
        loading: false,
        isMore: true,
        tabList: [
        ]
    };
  },

  mounted() {
    let _this = this
    _this.getMallPage()
    _this.getlist()
    window.addEventListener('scroll', function () {
      // 判断是否滚动到了底部
      if (_this.isScrollToBottom() && !_this.loading) {
        _this.loading = true;
        // 加载更多内容
        _this.loadmore()
      }
    });
  },

  methods: {
  
    isScrollToBottom() {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        return scrollTop + clientHeight >= scrollHeight-100;
    },
    loadmore() {
        this.pager.page += 1
        this.getlist()
        this.loading = false
    },
    // 跳转到详情
    goGoodDetails(id) {
        this.$router.push({
            name: `goodsdetail`,
            params: {
            id: id
            }
        })
    },
    // 跳转到搜索
    goshous() {
        this.$router.push('../classify/index')
    },
    // 获取首页 轮播 类型
    getMallPage() {
        var that = this
        mallIndex().then(res => {
            that.banners = res.data.banners
            that.tabList = res.data.categories
        })
    },
    // 推荐商品
    getlist() {
        var that = this
        if (that.isMore) {
            getmallList(that.pager).then(res => {
                that.goodsList = that.goodsList.concat(res.data.list)
                res.data.list.length !== that.pager.rows? that.isMore = false : that.isMore = true
            })
        }
    },
    // 跳转到分类
    goshoppings(id) {
        var that = this
        that.$router.push({
            name: `classify`,
            params: {
                id: id
            }
        })
    }
  },
};
</script>

<style lang="less" scoped>
.menu{
    width: 100vw;
    height: 66rem;
}
.home{
    background: #F6F7FA;
    .home_header{
        width: 100%;
        background: #fff;
        .my-swipe{
            margin: 12rem;
        }
    }
}
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20rem;
    // line-height: 150rem;
    text-align: center;
}
.tab_card{
    margin: 10rem 20rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-bottom: 10rem;
    .tab_nav{
        width: 25%;
        text-align: center;
        margin-top: 4rem;
        img{
            width: 46rem; 
        }
        .tab_text{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12rem;
            color: #333333;
            line-height: 17rem;
            font-style: normal;
        }
    }
}
 .lazy_box {
        padding-bottom: 20rem;
        margin: 0rem 2rem;
        column-count: 2;
        column-gap: 2rem;
        -moz-column-count:2; /* Firefox */
        -webkit-column-count:2; /* Safari and Chrome */
        .item {
            background: #fff;
            margin-bottom: 2rem;
            padding-bottom: 6rem;
            display: inline-block;
            img {
                width: 184rem;
                height: 184rem;
            }
            .intro{
                margin: 3rem 6rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 300;
                font-size: 14rem;
                color: #666666;
                line-height: 20rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .jiamu{
                display: flex;
                margin: 3rem 6rem;
                margin-top: 6rem;
                justify-content: space-between;
                align-items: center;
                .price{
                    font-family: DINPro, DINPro;
                    font-weight: 500;
                    font-size: 14rem;
                    color: #000000;
                }
                .stock{
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 300;
                    font-size: 12rem;
                    color: #939297
                }
            }
        }
    }
    .load_box{
        padding-bottom: 60rem;
        padding-top: 20rem;
        text-align: center;
    }
    .van-swipe-item{
        height: 198rem;
    }
</style>
